<template>
  <div class="mIntroduce">
    <div class="mIntroduce-top">
      <img  v-lazy="topBg" alt="" srcset="">
    </div>
    <div class="mIntroduce-top mIntroduce-knowledg">
      <img  v-lazy="knowledge" alt="" srcset="">
    </div>
    <div class="mIntroduce-top mIntroduce-health">
      <img  v-lazy="health" alt="" srcset="">
    </div>
    <div class="mIntroduce-top  mIntroduce-dailyData">
      <img  v-lazy="dailyData" alt="" srcset="" >
    </div>
    <div class="mIntroduce-top  mIntroduce-remind">
      <img  v-lazy="remind" alt="" srcset="" >
    </div>
    <div class="mIntroduce-top mIntroduce-account">
      <img  v-lazy="account" alt="" srcset="">
    </div>
    <div class="mIntroduce-top  mIntroduce-report">
      <img  v-lazy="report" alt="" srcset="">
    </div>
    <div class="mIntroduce-top mIntroduce-share">
      <img  v-lazy="share" alt="" srcset="">
    </div>
  
  </div>
</template>
<script>
export default {
  data() {
    return {
      topBg:require('../../assets/mi/introduce/1.png'),
      knowledge:require('../../assets/mi/introduce/2.png'),
      health:require('../../assets/mi/introduce/3.png'),
      dailyData:require('../../assets/mi/introduce/4.png'),
      remind:require('../../assets/mi/introduce/5.png'),
      report:require('../../assets/mi/introduce/6.png'),
      share:require('../../assets/mi/introduce/7.png'),
      account:require('../../assets/mi/introduce/8.png'),

    }
  }
}
</script>
<style lang="scss" scoped>
.mIntroduce-knowledg{
  img{
    height: 500px !important;
  }
}
.mIntroduce-health{
  img{
    height: 503px !important;
  }
}
.mIntroduce-dailyData{
  img{
    height: 836.5px !important;
  }
}
.mIntroduce-remind{
  img{
    height: 503px !important;
  }
}
.mIntroduce-report{
  img{
    height: 500px !important;
  }
}
.mIntroduce-share{
  img{
    height: 503px !important;
  }
}
.mIntroduce-account{
  img{
    height: 837px !important;
  }
}
.mIntroduce {
  .mIntroduce-top {
    font-size: 0px;
    img {
      width: 100%;
      height: 446px;
    }
  }
}
</style>